import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import { useAuth } from "../../components/firebase/authContext";
import {
  TestAccountLogin,
  handleLineLogin,
} from "../../components/lineApi/LineApiService";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSpring, animated } from "react-spring";
import {
  signInButtonStyle,
  signInButtoninnerDivStyle,
  boldTextStyle,
  maxWidth,
  footerHeightpx,
} from "../../styleTs/styleTs";
import { GoogleIconWhite } from "../../img/icons/svgIcons";
import topTableImg from "../../../src/img/top.jpg";
import logo from "../../../src/img/top_logo.svg";
import { getRedirectResult, UserCredential } from "firebase/auth";
import { auth } from "../../components/firebase/firebase";
import { handleFirebaseLineLogin } from "../../components/firebase/googleAuthService";

function LoginPage() {
  const navigate = useNavigate();
  // const { currentUser, login, logout } = useAuth();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const handleClose = () => {
    setOpenBackdrop(false);
  };
  const handleOpen = () => {
    setOpenBackdrop(true);
  };

  const ballDiameter = 30;
  const springProps = useSpring({
    from: { x: 0, y: 0 }, // 初期位置
    to: async (next) => {
      // var left = 0;
      // var right = window.innerWidth;
      // const bottom = window.innerHeight / 2;
      // var top = bottom - Math.floor(right * 0.56);
      // var marginDiv2 = Math.floor((window.innerWidth - maxWidth) / 2);
      // if (marginDiv2 > 0) {
      //   left = marginDiv2;
      //   right = right - marginDiv2;
      //   top = bottom - Math.floor(maxWidth * 0.56);
      // }

      var left = 0;
      var right = window.innerWidth;
      if (right > maxWidth) right = maxWidth;
      const bottom = Math.floor(right * 0.56);
      var top = 0;

      let x = left;
      let y = top;
      let xdir = 1;
      let ydir = 1;

      let diameter = ballDiameter;

      var prex = x;
      var prey = y;
      // var top = Math.floor(bottom - right * 0.56);

      while (true) {
        // 目標位置
        x += xdir; // x座標を1ずつ増減
        y += ydir; // y座標を1ずつ増減

        // 画面端に到達した場合、座標の増減方向を反転させる
        if (x + xdir >= right! - diameter || x + xdir <= left!) {
          xdir *= -1;
          const distance = Math.sqrt((x - prex) ** 2 + (y - prey) ** 2);
          const duration = (distance * 500) / 100;
          prex = x;
          prey = y;
          await next({ x, y, config: { duration: duration } });
        }
        if (y + ydir >= bottom! - diameter || y + ydir <= top!) {
          ydir *= -1;
          const distance = Math.sqrt((x - prex) ** 2 + (y - prey) ** 2);
          const duration = (distance * 500) / 100;
          prex = x;
          prey = y;
          await next({ x, y, config: { duration: duration } });
        }
      }
    },
    config: { duration: 1000 }, // アニメーションの設定
  });

  const shadowStyle = {
    filter: "drop-shadow(2px 4px 0px rgba(120,120,120, 0.5))",
    position: "static" as const,
  };

  const skewedLeftBlockStyle = (
    top: number,
    width: string,
    height: number
  ) => ({
    position: "absolute" as const,
    top: top,
    left: 0,
    width: width,
    height: `${height}px`,
    backgroundColor: "#27a5bb",
    zIndex: 100,
    clipPath: `polygon(0 0, 100% 0, calc(100% - ${
      0.6 * height
    }px) 100%, 0 100%)`,
  });

  const skewedRightBlockStyle = (height: number) => ({
    width: "100%",
    height: `${height}px`,
    backgroundColor: "#27a5bb",
    clipPath: `polygon(${0.6 * height}px 0, 100% 0,  100% 100%, 0 100%)`,
  });

  const skewedRightBlockDivStyle = (
    bottom: number,
    width: string
  ): React.CSSProperties => ({
    position: "absolute",
    bottom: bottom,
    right: 0,
    width: width,
    zIndex: 100,
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          width: "100%",
          maxWidth: `${maxWidth}px`,
        }}
      >
        <Stack
          direction="column"
          spacing={0}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "55vh",
          }}
        >
          <div
            style={{
              height: "5%",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "25%",
            }}
          >
            <img
              src={logo}
              alt="タクすき"
              height="65px"
              style={{ marginLeft: "-20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "70%",
            }}
          >
            <div style={{ margin: 0, position: "relative" }}>
              {/* 動くボール */}
              <animated.div
                style={{
                  position: "absolute",
                  top: springProps.y.to((y) => `${y}px`),
                  left: springProps.x.to((x) => `${x}px`),
                  width: `${ballDiameter}px`,
                  height: `${ballDiameter}px`,
                  borderRadius: "50%",
                  background: "#db6767",
                }}
              />
              <div style={shadowStyle}>
                <div style={skewedLeftBlockStyle(38, "26%", 8)}></div>
              </div>
              <div style={shadowStyle}>
                <div style={skewedLeftBlockStyle(-10, "45%", 40)}></div>
              </div>
              <div>
                <div style={shadowStyle}>
                  <div style={skewedLeftBlockStyle(-25, "30%", 25)}></div>
                </div>
              </div>
              <div style={skewedRightBlockDivStyle(-20, "60%")}>
                <div style={shadowStyle}>
                  <div style={skewedRightBlockStyle(40)}></div>
                </div>
              </div>
              <div style={skewedRightBlockDivStyle(-35, "40%")}>
                <div style={shadowStyle}>
                  <div style={skewedRightBlockStyle(8)}></div>
                </div>
              </div>
              <img src={topTableImg} alt="" width="100%" />
            </div>
          </div>
        </Stack>
        <Stack
          direction="column"
          spacing={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: `calc(45vh - ${footerHeightpx})`,
          }}
        >
          <Button
            onClick={() => {
              handleOpen();
              TestAccountLogin();
            }}
            variant="contained"
            sx={signInButtonStyle}
          >
            <div style={signInButtoninnerDivStyle}>
              <GoogleIconWhite />
              <b style={boldTextStyle}>testサインイン</b>
            </div>
          </Button>
          <Button
            onClick={() => {
              handleOpen();
              window.location.href =
                "https://3b1a-240f-e3-3182-1-90d4-6977-83cc-9bb3.ngrok-free.app/goto_lineLogin";
              // handleFirebaseLineLogin()
              //   .then((UserCredential: any) => {
              //     console.log("ログイン成功", UserCredential);
              //     navigate("/");
              //   })
              //   .catch((error: any) => {
              //     console.error("ログイン失敗", error);
              //     handleClose();
              //   });
            }}
            variant="contained"
            sx={signInButtonStyle}
          >
            <div style={signInButtoninnerDivStyle}>
              <GoogleIconWhite />
              <b style={boldTextStyle}>ラインログイン</b>
            </div>
          </Button>
          <Button
            onClick={() => {
              handleOpen();
              fetch(
                "https://3b1a-240f-e3-3182-1-90d4-6977-83cc-9bb3.ngrok-free.app/get-user-token",
                {
                  method: "GET",
                  credentials: "include", // クッキーをリクエストに含めるために必要
                }
              )
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Network response was not ok");
                  }
                  return response.json();
                })
                .then((data) => {
                  if (data.error) {
                    console.log(data.message);
                  } else {
                    console.log(data.message);
                  }
                })
                .catch((error) => {
                  console.log(error.message);
                });
            }}
            variant="contained"
            sx={signInButtonStyle}
          >
            <div style={signInButtoninnerDivStyle}>
              <GoogleIconWhite />
              <b style={boldTextStyle}>ライン取得ログイン</b>
            </div>
          </Button>
          <Card
            style={{
              width: "80%",
              boxShadow: "none",
              backgroundColor: "#f0f0f0",
              display: "flex",
              alignItems: "center",
            }}
            sx={{ p: 1 }}
          >
            <Typography style={{ textAlign: "left", fontSize: "11px" }}>
              ※現在ベータ版です。
              <br />
              ※開発のため予告なくデータを削除、変更する場合がございます。ご了承ください。
            </Typography>
          </Card>
        </Stack>
      </Box>
    </div>
  );
}
export default LoginPage;

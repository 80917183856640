import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React, { FC, useEffect, useRef, useState } from "react";
import { QrCodeReader } from "../components/QrCodeReader";
import { useAuth } from "../../components/firebase/authContext";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../components/firebase/firebase";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  runTransaction,
  query,
  limit,
  where,
  orderBy,
  or,
} from "firebase/firestore";
import BackIcon from "../../img/icons/ic_back.svg";
import getMainMaxWidth from "../../function/GetMainMaxWidth";
import {
  maxWidth,
  footerHeight,
  qrBackButton,
  footerBackBtnArea,
} from "../../styleTs/styleTs";
import AppBarSpace from "../../components/AppBarSpace";
import { SESSION_KEY } from "../../config";
import { getSessionParseJson } from "../../components/util";

function RateRanking() {
  const [roomList, setRoomList] = useState([]);
  const maxWidth = getMainMaxWidth();
  const { currentUser, userData, setUserData, setCurrentUser } = useAuth();

  useEffect(() => {
    async function fetchRoomList() {
      try {
        const q = query(
          collection(db, "rooms"),
          or(
            where("hostUser", "==", currentUser!.sub),
            where("guestUser", "==", currentUser!.sub)
          ),
          orderBy("roomId", "desc"), // roomidの降順で取得
          limit(5)
        );
        const querySnapshot = await getDocs(q);
        const roomListData: any = querySnapshot.docs.map((doc) => doc.data());
        console.log(roomListData);
        setRoomList(roomListData);
      } catch (error) {
        console.error("Error getting room list:", error);
      }
    }

    fetchRoomList();
  }, []); // 空の配列

  useEffect(() => {
    setCurrentUser!(getSessionParseJson(SESSION_KEY.USER));
    setUserData!(getSessionParseJson(SESSION_KEY.USER_DATA));
  }, []);

  const navigate = useNavigate();
  // テーブルの見出のスタイル
  const cellLeftStyle = {
    border: "none",
    fontSize: 12,
    fontWeight: "bold",
    color: "gray",
  };
  const displayNameStyle = {
    border: "none",
    fontSize: 14,
    fontWeight: "bold",
    color: "gray",
    textOverflow: "ellipsis",
  };
  // テーブルの要素の
  const cellRightStyle = {
    border: "none",
    fontSize: 12,
    color: "black",
    textOverflow: "ellipsis",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        minHeight: `calc(100vh - ${footerHeight}px)`,
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: `${maxWidth}px`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AppBarSpace />
        {roomList.map((user) => roomCard(user))}
        <div style={{ ...footerBackBtnArea, marginTop: "1rem" }}>
          <Button style={qrBackButton} component={Link} to="/taisen">
            <img src={BackIcon} alt="" width={16} height={16} />
            <p style={{ marginLeft: 10 }}>戻る</p>
          </Button>
        </div>
      </div>
    </div>
  );
  function roomCard(roomData: any) {
    return (
      <Card
        sx={{
          minWidth: 275,
          maxWidth: maxWidth,
          width: "95%",
          mt: 1,
          p: 1,
          background: roomData.finish ? "gray" : "white",
        }}
        onClick={() => {
          // カードがクリックされたときの処理
          console.log("Card clicked!");
          // 画面遷移を実行する例（遷移先のURLと一緒にデータを渡す）
          const path = `/taisen/taisenroom/${roomData.roomId}`;
          navigate(path);
        }}
      >
        <p>ルームID：{roomData.roomId!}</p>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          alignContent="center"
        >
          {roomData.finish ? (
            roomData.hostUserMotoRate < roomData.hostNewRate ? (
              <p style={{ fontSize: "1.5rem" }}>win</p>
            ) : (
              <p style={{ fontSize: "1.5rem" }}>lose</p>
            )
          ) : (
            <div></div>
          )}

          <Stack direction="column" spacing={1} alignItems="center">
            <Avatar
              alt="hostPhoto"
              src={roomData.hostUserPhoto!}
              sx={{ width: 60, height: 60 }}
            />
            {roomData.hostUserName!}
          </Stack>
          <p style={{ fontSize: "1.5rem" }}>vs</p>

          <Stack direction="column" spacing={1} alignItems="center">
            <Avatar
              alt="guestPhoto"
              src={roomData.guestUserPhoto!}
              sx={{ width: 60, height: 60 }}
            />
            {roomData.guestUserName! ?? "募集中"}
          </Stack>
          {roomData.finish ? (
            roomData.guestUserMotoRate < roomData.guestNewRate ? (
              <p style={{ fontSize: "1.5rem" }}>win</p>
            ) : (
              <p style={{ fontSize: "1.5rem" }}>lose</p>
            )
          ) : (
            <div></div>
          )}
        </Stack>
      </Card>
    );
  }
}

export default RateRanking;

import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import { onAuthStateChange } from "./googleAuthService";
import {
  firebaseAssignUserID,
  firebaseGetUserData,
  firebaseSetUserData,
  firebaseUpdateUserData,
} from "./userDataService";
type AuthContextType = {
  currentUser: any | null;
  userData: any;
  setCurrentUser?: (user: any | null) => void;
  setUserData?: (userData: any | null) => void;
};

const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  userData: null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<any | null>(null);
  const [userData, setUserData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChange(async (user: any) => {
      try {
        setLoading(true);
        if (user) {
          setCurrentUser({ provider_id: user.uid });
          const fetchedUserData = await firebaseGetUserData(user.uid);

          if (fetchedUserData) {
            if (user.displayName !== fetchedUserData.userDisplayName) {
              await firebaseUpdateUserData(user.uid, {
                userDisplayName: user.displayName,
              });
            }

            if (user.photoURL !== fetchedUserData.userPhotoURL) {
              await firebaseUpdateUserData(user.uid, {
                userPhotoURL: user.photoURL!,
              });
            }
            setUserData(fetchedUserData);
          } else {
            console.log("No such document!");
            const userId = await firebaseAssignUserID();
            await firebaseSetUserData(user.uid, {
              userId: userId,
              providerId: user.uid,
              userDisplayName: user.displayName,
              userPhotoURL: user.photoURL!,
              rate: 1500,
            });
            console.log("New user document added!");
            const newUserData = await firebaseGetUserData(user.uid);
            setUserData(newUserData);
          }
        } else {
          setCurrentUser(null);
          setUserData(null);
        }
      } catch (error) {
        console.error("Error fetching or updating user data: ", error);
        // エラーメッセージを表示したり、UIにフィードバックを与えるための処理をここに追加できます。
      } finally {
        setLoading(false);
      }
    });
    return () => unsubscribe(); // 正確なクリーンアップ関数の形式
  }, []);

  const value: AuthContextType = {
    currentUser,
    userData,
    setCurrentUser,
    setUserData,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import React, { useEffect } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../firebase/authContext";
import { SESSION_KEY } from "../../config";
import { getSessionParseJson, setSessionToJson } from "../util";
import { accessSync } from "fs";
import {
  firebaseAssignUserID,
  firebaseGetUserData,
  firebaseSetUserData,
} from "../firebase/userDataService";
import { getSession, setSession } from "../fastAPI/fastAPIfunction";

const LINE_WEB_LOGIN_STATE = "line_web_login_state";
const ACCESS_TOKEN = "accessToken";
const ID_TOKEN = "idToken";
const NONCE = "nonce";

const channelId = "2005090941"; // あなたのチャネルIDをここに設定
const channelSecret = "c3313eaf1063c7a937ff2fbaa303e440";
const callbackUrl = "https://test.yuyu-log.com/line-auth";

const getLineWebLoginUrl = (
  state: string,
  nonce: string,
  scopes: string[]
): string => {
  const encodedCallbackUrl = encodeURIComponent(callbackUrl);
  const scope = scopes.join("%20");

  return (
    `https://access.line.me/oauth2/v2.1/authorize?response_type=code` +
    `&client_id=${channelId}` +
    `&redirect_uri=${encodedCallbackUrl}` +
    `&state=${state}` +
    `&scope=${scope}` +
    `&nonce=${nonce}`
  );
};

export const handleLineLogin = () => {
  const state = secureRandomString();
  const nonce = secureRandomString();
  const scopes = ["openid", "profile"];
  const url = getLineWebLoginUrl(state, nonce, scopes);

  // セッションにstateとnonceを保存（ここではsessionStorageを使用）
  sessionStorage.setItem(LINE_WEB_LOGIN_STATE, state);
  sessionStorage.setItem(NONCE, nonce);

  // LINEログインページにリダイレクト
  window.location.href = url;
};

export const handleLineLogout = async (navigate: NavigateFunction) => {
  const access_token = getSessionParseJson(SESSION_KEY.LINE_ACCESSTOKEN);
  if (access_token) await revokeAccessToken(access_token);

  sessionStorage.removeItem(SESSION_KEY.USER);
  sessionStorage.removeItem(SESSION_KEY.USER_DATA);
  sessionStorage.removeItem(SESSION_KEY.LINE_ACCESSTOKEN);

  navigate("/login");
};

const secureRandomString = (): string => {
  const array = new Uint32Array(10);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => dec.toString(36)).join("");
};

export interface AccessToken {
  scope: string;
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  id_token: string;
}

export interface IdToken {
  iss: string;
  sub: string;
  aud: string;
  exp: number;
  iat: number;
  nonce: string;
  amr: string[];
  name: string;
  picture: string;
}

interface Verify {
  scope: string;
  client_id: string;
  expires_in: number;
}

const requestAccessToken = async (
  code: string
): Promise<AccessToken | null> => {
  try {
    const url = "https://api.line.me/oauth2/v2.1/token";
    const data = new URLSearchParams({
      grant_type: "authorization_code",
      code,
      redirect_uri: callbackUrl,
      client_id: channelId,
      client_secret: channelSecret,
    });

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: data.toString(),
    });

    if (!response.ok) {
      throw new Error(
        `Failed to fetch access token, HTTP response code: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Failed to request access token:", error);
    return null;
  }
};

const refreshAccessToken = async (
  accessToken: AccessToken
): Promise<AccessToken | null> => {
  try {
    const url = "https://api.line.me/oauth2/v2.1/token";
    const data = new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: accessToken.refresh_token,
      client_id: channelId,
      client_secret: channelSecret,
    });

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: data.toString(),
    });

    if (!response.ok) {
      throw new Error(
        `Failed to refresh access token, HTTP response code: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Failed to refresh access token:", error);
    return null;
  }
};

const verifyAccessToken = async (
  accessToken: AccessToken
): Promise<Verify | null> => {
  try {
    const url = `https://api.line.me/oauth2/v2.1/verify?access_token=${encodeURIComponent(
      accessToken.access_token
    )}`;
    const response = await fetch(url, { method: "GET" });

    if (!response.ok) {
      throw new Error(
        `Failed to verify access token, HTTP response code: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Failed to verify access token:", error);
    return null;
  }
};

const revokeAccessToken = async (accessToken: AccessToken): Promise<void> => {
  try {
    const url = "https://api.line.me/oauth2/v2.1/revoke";
    const data = new URLSearchParams({
      client_id: channelId,
      client_secret: channelSecret,
      access_token: accessToken.access_token,
    });

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: data.toString(),
    });

    if (!response.ok) {
      throw new Error(
        `Failed to revoke access token, HTTP response code: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error during token revocation:", error);
  }
};

const verifyIdToken = async (idToken: string): Promise<IdToken | null> => {
  try {
    const url = "https://api.line.me/oauth2/v2.1/verify";
    const data = new URLSearchParams({
      id_token: idToken,
      client_id: channelId,
    });

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: data.toString(),
    });

    if (!response.ok) {
      throw new Error(
        `Failed to verify ID token, HTTP response code: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error during ID token verification:", error);
    return null;
  }
};

// Main component
export const LineAuthCallbackHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setCurrentUser, setUserData } = useAuth();

  useEffect(() => {
    const handleAuthCallback = async () => {
      const query = new URLSearchParams(location.search);
      const code = query.get("code");
      const state = query.get("state");
      const error = query.get("error");
      const errorCode = query.get("errorCode");
      const errorMessage = query.get("errorMessage");

      if (error || errorCode || errorMessage) {
        console.error("Login error:", { error, errorCode, errorMessage });
        navigate("/");
        return;
      }

      const sessionState = sessionStorage.getItem(LINE_WEB_LOGIN_STATE);

      if (!state || state !== sessionState) {
        console.error("Invalid state parameter");
        navigate("/");
        return;
      }

      sessionStorage.removeItem(LINE_WEB_LOGIN_STATE);

      const accessToken = await requestAccessToken(code!);
      setSessionToJson(SESSION_KEY.LINE_ACCESSTOKEN, accessToken);
      if (accessToken) {
        const idToken = await verifyIdToken(accessToken.id_token);
        sessionStorage.removeItem(NONCE);
        if (idToken) {
          setSessionToJson(SESSION_KEY.USER, { provider_id: idToken.sub });
          setCurrentUser!({ provider_id: idToken.sub });
          let user_data = await firebaseGetUserData(idToken.sub);

          if (user_data) {
            setUserData!(user_data);
            setSessionToJson(SESSION_KEY.USER_DATA, user_data);
          } else {
            console.log("No such document!");
            const userId = await firebaseAssignUserID();
            await firebaseSetUserData(idToken.sub, {
              userId: userId,
              providerId: idToken.sub,
              userDisplayName: idToken.name,
              userPhotoURL: idToken.picture,
              rate: 1500,
            });
            console.log("New user document added!");
            const newUserData = await firebaseGetUserData(idToken.sub);
            setUserData!(newUserData);
            setSessionToJson(SESSION_KEY.USER_DATA, newUserData);
          }
          navigate("/");
        } else {
          console.error("Failed to verify ID token");
          navigate("/");
        }
      } else {
        console.error("Failed to fetch access token");
        navigate("/");
      }
    };

    handleAuthCallback();
  }, [location, navigate]);

  return (
    <div>
      <h1>Logging in with LINE...</h1>
    </div>
  );
};

// Main component
export const TestAccountLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setCurrentUser, setUserData } = useAuth();

  useEffect(() => {
    const handleAuthCallback = async () => {
      const testAccountId = "AsfFF0RVnLevcpQBEPgRZRmm4Uo1";
      let user_data = await firebaseGetUserData(testAccountId);
      if (user_data) {
        setUserData!(user_data);
      } else {
        console.log("No such document!");
        const userId = await firebaseAssignUserID();
        await firebaseSetUserData(testAccountId, {
          userId: userId,
          providerId: testAccountId,
          userDisplayName: "テストアカウント",
          userPhotoURL: "",
          rate: 1500,
        });
        console.log("New user document added!");
        const newUserData = await firebaseGetUserData(testAccountId);
        setUserData!(newUserData);
        setSessionToJson(SESSION_KEY.USER_DATA, newUserData);
      }
      navigate("/");
    };

    handleAuthCallback();
  }, [location, navigate]);

  return (
    <div>
      <h1>Logging in with LINE...</h1>
    </div>
  );
};

// Main component
export const FastApiLineAuthCallbackHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setCurrentUser, setUserData } = useAuth();

  useEffect(() => {
    const handleAuthCallback = async () => {
      const query = new URLSearchParams(location.search);
      const id_token = query.get("id_token");
      const idToken = await verifyIdToken(id_token!);
      if (idToken) {
        setCurrentUser!({ provider_id: idToken.sub });
        let user_data = await firebaseGetUserData(idToken.sub);
        if (user_data) {
          setUserData!(user_data);
          setSessionToJson(SESSION_KEY.USER_DATA, user_data);
          // setSession("user_data", JSON.stringify(user_data));
        } else {
          console.log("No such document!");
          const userId = await firebaseAssignUserID();
          await firebaseSetUserData(idToken.sub, {
            userId: userId,
            providerId: idToken.sub,
            userDisplayName: idToken.name,
            userPhotoURL: idToken.picture,
            rate: 1500,
          });
          console.log("New user document added!");
          const newUserData = await firebaseGetUserData(idToken.sub);
          setUserData!(newUserData);
          setSessionToJson(SESSION_KEY.USER_DATA, newUserData);
        }

        // const user__data = getSession("user_data");
        navigate("/");
      } else {
        console.error("Failed to verify ID token");
        navigate("/");
      }
    };

    handleAuthCallback();
  }, [location, navigate]);

  return (
    <div>
      <h1>Logging in with LINE...</h1>
    </div>
  );
};

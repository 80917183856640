import React, { useState, useRef, useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  runTransaction,
} from "firebase/firestore";
import { auth, db } from "../../components/firebase/firebase";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { QrCodeReader } from "../components/QrCodeReader";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useAuth } from "../../components/firebase/authContext";
import Stack from "@mui/material/Stack";
import AppBarSpace from "../../components/AppBarSpace";
import FooterSpace from "../../components/FooterSpace";
import { maxWidth, footerHeight } from "../../styleTs/styleTs";
import roomSakuseiIcon from "../../img/icons/room_sakusei.svg";
import roomSankaIcon from "../../img/icons/room_sanka.svg";
import RankingIcon from "../../img/icons/ic_rate_ranking.svg";
import {
  taisenRoomInnerDiv1,
  taisenRoomInnerDiv2,
  taisenRoomOuterDiv,
  taisenRoomButton,
  skewedLeftGrayBlockStyle,
  skewedRightBlockStyle,
} from "../../styleTs/styleTs";

export function Taisen() {
  const [isOpenQRCamera, setIsOpenQRCamera] = useState<boolean>(false);
  const [qrResult, setQrResult] = useState<any>({});
  const navigate = useNavigate();
  const { currentUser, userData, setUserData, setCurrentUser } = useAuth();

  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     if (currentUser) {
  //       try {
  //         const data = await getUserData(currentUser.provider_id);
  //         setUserData!(data);
  //       } catch (error) {
  //         console.error("Error fetching user data:", error);
  //       }
  //     }
  //   };

  //   fetchUserData();
  // }, [])

  const clickOpenQrReader = () => {
    setIsOpenQRCamera(true);
  };

  const navi = (e: any) => {
    navigate(`/taisen/taisenroom/${e.text}`);
  };

  const [newRoomId, setNewRoomId] = useState(null);

  const createNewRoom = async () => {
    try {
      const result = await runTransaction(db, async (transaction) => {
        const roomInfoDoc = doc(db, "rooms", "roomsInfo");
        const roomInfoDocSnap = await transaction.get(roomInfoDoc);

        const currentRoomId = roomInfoDocSnap?.data()?.currentRoomId;
        const newRoomId = currentRoomId + 1;

        // ルームをデータベースに作成
        const newRoomDoc = doc(db, "rooms", newRoomId.toString());
        transaction.set(newRoomDoc, {
          createDt: new Date(), // 作成日時を追加
          roomId: newRoomId.toString(),
          hostUser: userData.providerId,
          hostUserName: userData!.userDisplayName,
          hostUserPhoto: userData!.userPhotoURL,
          hostUserMotoRate: userData!.rate,
        });

        transaction.update(roomInfoDoc, { currentRoomId: newRoomId });
        return newRoomId;
      });
      const path = `/taisen/taisenroom/${result}?userKind=Host`;
      navigate(path);
      console.log("New room created with ID:", result);
    } catch (e) {
      console.error("Error creating new room:", e);
    }
  };

  const entryRoom = async (roomId: any) => {
    try {
      const result = await runTransaction(db, async (transaction) => {
        //　ルームデータを取得
        const roomDoc = doc(db, "rooms", roomId.toString());
        const roomSnapshot = await getDoc(roomDoc);
        // もしドキュメントが存在し、guestUserフィールドがある場合に処理を行う
        if (!roomSnapshot.exists()) {
          console.log("ルームが存在しません。");
          return null;
        } else {
          if (roomSnapshot.data().guestUser) {
            // guestUserの値が存在する場合の処理
            console.log(
              "ルームに別のユーザーが存在します。:",
              roomSnapshot.data().guestUser
            );
            return null;
          } else if (roomSnapshot.data().hostUser == userData!.userId) {
            console.log(
              "ルームに同一IDのユーザーが存在します。:",
              roomSnapshot.data().guestUser
            );
            return null;
          } else {
            await updateDoc(roomDoc, {
              guestUser: userData!.providerId,
              guestUserMotoRate: userData!.rate,
              guestUserName: userData!.userDisplayName,
              guestUserPhoto: userData!.userPhotoURL,
            });
          }
          return true;
        }
      });
      if (result) {
        const path = `/taisen/taisenroom/${roomId}?userKind=Guest`;
        navigate(path);
      } else {
        alert("ルームに入れません。");
      }
    } catch (e) {
      console.error("Error creating new room:", e);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: `calc(100vh - ${footerHeight}px)`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: `${maxWidth}px`,
          }}
        >
          <AppBarSpace />
          <div
            style={{
              marginTop: "20px",
              height: "60px",
              position: "relative",
              marginBottom: "5px",
            }}
          >
            <div style={skewedLeftGrayBlockStyle(0, "90%", 50)}>レート対戦</div>
            <div style={skewedRightBlockStyle(30, "45%", 30)}></div>
          </div>
          {/* ルーム作成・参加 */}
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "100%",
              maxWidth: maxWidth,
              p: 2,
            }}
          >
            <div style={taisenRoomOuterDiv} onClick={createNewRoom}>
              <div style={taisenRoomInnerDiv1}>ルームを作成</div>
              <div style={taisenRoomInnerDiv2}>
                <img src={roomSakuseiIcon} alt="" width={60} height={60} />
              </div>
            </div>
            <div style={taisenRoomOuterDiv} onClick={clickOpenQrReader}>
              <div style={taisenRoomInnerDiv1}>ルームに参加</div>
              <div style={taisenRoomInnerDiv2}>
                <img src={roomSankaIcon} alt="" width={60} height={60} />
              </div>
            </div>
          </Stack>
          {/* ランキング */}
          <Button
            variant="contained"
            style={taisenRoomButton}
            sx={{ m: 1 }}
            component={Link}
            to="/rateranking"
          >
            <img src={RankingIcon} alt="" width={25} height={25} />
            <p style={{ marginLeft: 10 }}>レートランキング</p>
          </Button>
          {/* ルーム履歴 */}
          <Button
            variant="contained"
            style={taisenRoomButton}
            sx={{ m: 1 }}
            component={Link}
            to="/roomhistory"
          >
            <p>ルーム履歴</p>
          </Button>
        </Box>
      </div>
      {isOpenQRCamera && (
        <QrCodeReader
          onRead={(e) => {
            setIsOpenQRCamera(false);
            console.log(e);
            setQrResult(e);
            entryRoom(e);
          }}
          setOpen={setIsOpenQRCamera}
        />
      )}
    </div>
  );
}

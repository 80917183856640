import {
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
  signInWithRedirect,
  OAuthProvider,
} from "firebase/auth";
import { auth } from "./firebase";

// Googleでログイン
export const handleFirebaseGooglelogin = async () => {
  const provider = new GoogleAuthProvider();
  return await signInWithPopup(auth, provider);
};

export const handleFirebaseLineLogin = async () => {
  const provider = new OAuthProvider("oidc.line-auth");
  return await signInWithPopup(auth, provider);
};

// ログアウト
export const logout = async () => {
  return await signOut(auth);
};

// 認証状態の変更を監視
export const onAuthStateChange = (onAuthStateChangedHandler: any) => {
  return onAuthStateChanged(auth, onAuthStateChangedHandler);
};

import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  runTransaction,
} from "firebase/firestore";
import { db } from "./firebase";
import { useAuth } from "./authContext";

// ユーザーデータの取得
export const firebaseGetUserData = async (uid: string) => {
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? docSnap.data() : null;
};

// ユーザーデータの更新
export const firebaseUpdateUserData = async (uid: string, data: any) => {
  const userRef = doc(db, "users", uid);
  await updateDoc(userRef, data);
};

// ユーザーデータの追加
export const firebaseSetUserData = async (uid: string, data: any) => {
  const userRef = doc(db, "users", uid);
  await setDoc(userRef, data);
};

export const firebaseAssignUserID = async () => {
  try {
    const result = await runTransaction(db, async (transaction) => {
      const usersInfoDoc = doc(db, "users", "usersInfo");
      const usersInfoDocSnap = await transaction.get(usersInfoDoc);

      const currentUserId = usersInfoDocSnap?.data()?.currentUserNum;
      const newUserId = currentUserId + 1;

      transaction.update(usersInfoDoc, { currentUserNum: newUserId });

      return newUserId;
    });
    console.log("New UserID:", result);
    return result;
  } catch (e) {
    console.error("Error creating new room:", e);
  }
};

import React from "react";
import { useAuth } from "./components/firebase/authContext";
import { Navigate, useLocation } from "react-router-dom";
import { setSessionToJson, getSessionParseJson } from "./components/util";
import { SESSION_KEY } from "./config";

type Props = {
  component: React.ReactNode;
  redirect: string;
};

export const PrivateRoute: React.VFC<Props> = (props) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const sessionUser = getSessionParseJson(SESSION_KEY.USER_DATA);
  if (currentUser || sessionUser) {
    return <>{props.component}</>;
  }
  return (
    <Navigate to={props.redirect} state={{ from: location }} replace={false} />
  );
};

export const LoginedRoute: React.VFC<Props> = (props) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const sessionUser = getSessionParseJson(SESSION_KEY.USER_DATA);
  if (!currentUser && !sessionUser) {
    return <>{props.component}</>;
  }
  return (
    <Navigate to={props.redirect} state={{ from: location }} replace={false} />
  );
};

import Grid from "@mui/material/Grid"; // Grid version 1
import Box from "@mui/material/Box";
import AdCard2 from "../../components/AdComponent/adCard2";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Youtube from "../../components/youtube/youtube";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Card from "@mui/material/Card";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Stack, TextField, Button, MenuItem, InputLabel } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import React, { FC, useEffect, useRef, useState } from "react";
import { QrCodeReader } from "../components/QrCodeReader";
import { useAuth } from "../../components/firebase/authContext";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../components/firebase/firebase";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  runTransaction,
  query,
  limit,
  where,
} from "firebase/firestore";
import getMainMaxWidth from "../../function/GetMainMaxWidth";
import { BottomRow } from "blockly/core/renderers/zelos/zelos";
import UserProfile from "../userList/userProfile";
import AppBarSpace from "../../components/AppBarSpace";
import { tochigiCity } from "../../config";
import Divider from "@mui/material/Divider";
import {
  editProfileCancel,
  editProfileSubmit,
  footerHeight,
} from "../../styleTs/styleTs";
import Modal from "@mui/material/Modal";
import {
  heading1Style,
  editButtonStyle,
  grayEllipseStyle,
  infoText,
  maxWidth,
  commentArea,
  grayEllipseLinkStyle,
  appBarHeight,
  grayEllipseStyleUserList,
} from "../../styleTs/styleTs";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import linkIcon from "../../../src/img/icons/ic_link.svg";
import youtubeIcon from "../../../src/img/icons/ic_youtube.svg";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import shakeRaket from "../../../src/img/shake_racket.svg";
import penRaket from "../../../src/img/pen_racket.svg";
import penRaket2 from "../../../src/img/pen2_racket.svg";

type Inputs = {
  city: string;
  // Gender: string;
};

function UserList() {
  const [city, setCity] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setCity(event.target.value);
  };

  const [userList, setUserList] = useState([]);
  const maxWidth = getMainMaxWidth();

  // const [propsUserData, setPropsUserData] = useState({});
  interface UserDataType {
    [key: string]: any;
  }
  const [showUserData, setShowUserData] = useState<UserDataType>({});

  const [clickIndex, setClickIndex] = useState({});

  // 検索モーダル
  const [searchOpen, setSearchOpen] = React.useState(false);
  const searchHandleOpen = () => setSearchOpen(true);
  const searchHandleClose = () => setSearchOpen(false);

  //ユーザプロフィールモーダル
  const [prfOpen, setPrfOpen] = React.useState(false);
  const prfHandleOpen = () => setPrfOpen(true);
  const prfHandleClose = () => setPrfOpen(false);

  // urlチェック
  function isValidUrl(url: string) {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }

  const HtmlTooltip = styled(
    ({ className, bdColor, ...props }: TooltipProps & { bdColor?: string }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    )
  )(({ theme, bdColor = "#37a59a" }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 1)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: `5px solid ${bdColor}`,
    },
  }));

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    // デフォルト値、　nameで設定する
    defaultValues: {
      city: "---",
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    async function fetchUserList() {
      try {
        // データベースからユーザーリストをクエリ
        const q = query(
          collection(db, "users"),
          where("public", "==", true),
          where("baseCity", "==", data.city)
        );

        // クエリの実行とデータの取得
        const querySnapshot = await getDocs(q);

        // ドキュメントのデータを配列にマップ
        const userListData: any = querySnapshot.docs.map((doc) => doc.data());

        // ユーザーリストをセット
        setUserList(userListData);
      } catch (error) {
        // エラーハンドリング
        console.error("ユーザーリストの取得エラー:", error);
      }
    }
    fetchUserList();

    //モーダル閉じる
    searchHandleClose();
  };

  useEffect(() => {
    async function fetchUserList() {
      try {
        const q = query(
          collection(db, "users"),
          where("public", "==", true),
          limit(10)
        );
        const querySnapshot = await getDocs(q);
        const userListData: any = querySnapshot.docs.map((doc) => doc.data());

        setUserList(userListData);
      } catch (error) {
        console.error("Error getting user list:", error);
      }
    }

    fetchUserList();
  }, []); // 空の配列

  const navigate = useNavigate();
  // テーブルの見出のスタイル
  const cellLeftStyle = {
    border: "none",
    fontSize: 12,
    fontWeight: "bold",
    color: "gray",
  };
  const displayNameStyle = {
    border: "none",
    fontSize: 14,
    fontWeight: "bold",
    color: "gray",
    textOverflow: "ellipsis",
  };
  // テーブルの要素の
  const cellRightStyle = {
    border: "none",
    fontSize: 12,
    color: "black",
    textOverflow: "ellipsis",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          height: `calc(100vh - ${footerHeight}px)`,
          maxWidth: `${maxWidth}px`,
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <AppBarSpace />
        <Stack
          direction="row"
          sx={{
            width: "95%",
            mt: 1,
            p: 1,
          }}
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <p>検索条件設定</p>
          {/* <Button
            variant="contained"
            onClick={getUserList}
            sx={{ width: "20%", height: 35 }}
          >
            検索
          </Button> */}
          <Button
            variant="contained"
            onClick={searchHandleOpen}
            sx={{ width: "20%", height: 35 }}
          >
            検索
          </Button>
        </Stack>
        {userList.map((user) => userCard(user, 1))}

        {/* {userList.map((user, index) => (
          <React.Fragment key={index}>
            {index !== clickIndex ? (
              userCard(user, index)
            ) : (
              <UserProfile userData={propsUserData} />
            )}
          </React.Fragment>
        ))} */}
      </div>
      <Modal
        open={searchOpen}
        onClose={searchHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: `${maxWidth * 0.8}px`,
              height: "350px",
              background: "white",
              borderRadius: " 1rem",
            }}
          >
            {/* タイトル */}
            <div
              style={{
                display: "flex",
                background: "#37a59a",
                height: "50px",
                borderRadius: " 1rem 1rem 0 0",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "white",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              検索条件設定
            </div>
            {/* 中 */}
            <div
              style={{
                background: "white",
                height: "75%",
                overflowY: "auto",
              }}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                direction="column"
                spacing={2}
                sx={{ m: 2 }}
              >
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <FormControl variant="standard" sx={{ width: "90%" }}>
                      <InputLabel id="label-city">市町村</InputLabel>
                      <Select
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        variant="standard"
                        labelId="label-raketType"
                      >
                        <MenuItem value="">---</MenuItem>
                        {tochigiCity.map((tochigiCity) => (
                          <MenuItem key={tochigiCity} value={tochigiCity}>
                            {tochigiCity}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Stack>
            </div>
            {/* ボタンエリア */}
            <div
              style={{
                background: "white",
                height: "15%",
                borderRadius: "0 0 1rem 1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTop: "1px solid #AAAAAA",
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: "100%",
                  background: "bule",
                }}
              >
                <Button
                  onClick={searchHandleClose}
                  variant="outlined"
                  sx={editProfileCancel}
                >
                  キャンセル
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={editProfileSubmit}
                >
                  決定
                </Button>
              </Stack>
            </div>
          </div>
        </form>
      </Modal>
      <Modal
        open={prfOpen}
        onClose={prfHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: `calc(100% - ${appBarHeight}px)`,
            background: "white",
            transform: `translateY(${appBarHeight}px)`,
            position: "relative",
          }}
        > */}
        <div
          style={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95%",
            maxWidth: `${maxWidth * 0.8}px`,
            height: "90%",
            background: "white",
            borderRadius: " 1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              background: "#37a59a",
              height: "60px",
              borderRadius: " 1rem 1rem 0 0",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              color: "white",
              fontSize: "18px",
              fontWeight: "bold",
              position: "relative",
            }}
          >
            メンバープロフィール
            <IconButton
              sx={{
                width: "30px",
                height: "30px",
                position: "absolute", // 絶対位置を指定
                top: "50%", // 親要素の上端からの距離
                right: "20px", // 親要素の右端からの距離
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={() => {
                prfHandleClose();
              }}
            >
              <ClearIcon />
            </IconButton>
            {/* <button
              style={{
                position: "absolute", // 絶対位置を指定
                top: 0, // 親要素の上端からの距離
                right: 0, // 親要素の右端からの距離
                margin: "10px", // 余白を加えることで、端から少し離れた位置に配置
                cursor: "pointer", // ホバー時にカーソルをポインターに変更
              }}
              onClick={prfHandleClose}
            >
              閉じる
            </button> */}
          </div>
          <Box
            sx={{
              width: "100%",
              maxWidth: `${maxWidth}px`,
              height: "calc(100% - 60px)",
              overflow: "auto",
            }}
          >
            <Box sx={{ pt: 3, pl: 3, pr: 3, pb: 3 }}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  mb: 3,
                  width: "100%",
                  maxWidth: maxWidth,
                }}
              >
                <Avatar
                  alt="myPhoto"
                  src={showUserData?.userPhotoURL}
                  sx={{
                    width: 100,
                    height: 100,
                    border: "4px solid #37a59a", // 縁の設定
                  }}
                />
                <Stack
                  direction="column"
                  spacing={0.8}
                  sx={{ m: 1, width: "100%", maxWidth: maxWidth }}
                >
                  <div style={grayEllipseStyle}>
                    {"No." + String(showUserData?.userId).padStart(5, "0")}
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      textAlign: "left",
                      padding: "5px 0 0 30px",
                    }}
                  >
                    {showUserData.displayName}
                  </div>
                  <Divider />
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ alignItems: "center" }}
                  >
                    <div
                      style={{
                        width: "100px",
                        borderRadius: "30px",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "13px",
                        background: "#37a59a",
                        padding: "2px 0 2px 0",
                        textAlign: "center" as const,
                      }}
                    >
                      レート
                    </div>
                    <div
                      style={{
                        width: "80px",
                        color: "#37a59a",
                        fontWeight: "bold",
                        fontSize: "18px",
                        padding: "0",
                      }}
                    >
                      {showUserData?.rate ?? ""}
                    </div>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ width: "100%", maxWidth: maxWidth, height: "2rem" }}
              >
                <div style={heading1Style}>
                  <p style={{ margin: 0, textAlign: "center" }}>ラケット情報</p>
                </div>
              </Stack>
              {/* ラケット情報 */}
              <Stack
                alignItems="center"
                justifyContent="center"
                spacing={1}
                sx={{ mt: 2, mb: 0 }}
                direction="row"
              >
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      showUserData?.raketType === "シェーク"
                        ? shakeRaket
                        : showUserData?.raketType === "ペン（両面）"
                        ? penRaket
                        : showUserData?.raketType === "ペン（片面）"
                        ? penRaket2
                        : shakeRaket
                    }
                    alt="Raket Type"
                    style={{ maxWidth: "80%", maxHeight: "80%" }}
                  />
                </div>

                <Stack
                  style={{
                    width: "calc(100% - 100px)",
                    height: "100px",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    background: "#e5e5e5",
                    borderRadius: "0.5rem",
                    padding: "0.5rem 1rem 0.5rem 1rem",
                  }}
                >
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <p>{showUserData?.raket}</p>
                        <p>変更日： {showUserData?.rDate ?? ""}</p>
                        <p>タイプ： {showUserData?.raketType ?? ""}</p>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <Typography
                      sx={{
                        ...cellRightStyle,
                      }}
                    >
                      ラケット：
                      {showUserData?.raket ?? ""}
                    </Typography>
                  </HtmlTooltip>
                  <HtmlTooltip
                    bdColor={showUserData?.fColor}
                    title={
                      <React.Fragment>
                        <p>{showUserData?.fRubber}</p>
                        <p>変更日： {showUserData?.fDate ?? ""}</p>
                        <p>厚さ： {showUserData?.fAtusa ?? ""}</p>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <Typography
                      sx={{
                        ...cellRightStyle,
                      }}
                    >
                      フォア　：
                      {showUserData?.fRubber ?? ""}
                    </Typography>
                  </HtmlTooltip>
                  <HtmlTooltip
                    bdColor={showUserData?.bColor}
                    title={
                      <React.Fragment>
                        <p>{showUserData?.bRubber}</p>
                        <p>変更日： {showUserData?.bDate ?? ""}</p>
                        <p>厚さ： {showUserData?.bAtusa ?? ""}</p>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <Typography
                      sx={{
                        ...cellRightStyle,
                      }}
                    >
                      バック　：
                      {showUserData?.bRubber ?? ""}
                    </Typography>
                  </HtmlTooltip>
                </Stack>
              </Stack>
              <Typography
                sx={{
                  border: "none",
                  fontSize: 12,
                  color: "gray",
                  width: "90%",
                  textAlign: "right",
                  // marginRight: "5rem",
                }}
              >
                ※長押しで詳細を確認
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: "100%",
                  maxWidth: maxWidth,
                  height: "2rem",
                  marginBottom: 2,
                }}
              >
                <div style={heading1Style}>
                  <p style={{ margin: 0, textAlign: "center" }}>基本情報</p>
                </div>
              </Stack>
              <Stack
                direction="column"
                spacing={1.5}
                sx={{ width: "100%", maxWidth: maxWidth }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>年代</div>
                  <div style={infoText}> {showUserData?.age ?? ""}</div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>性別</div>
                  <div style={infoText}>　{showUserData?.sex ?? ""}</div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>卓球歴</div>
                  <div style={infoText}> {showUserData?.experience ?? ""}</div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>戦型</div>
                  <div style={infoText}> {showUserData?.playStyle ?? ""}</div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>活動エリア</div>
                  <div style={infoText}>{showUserData?.base ?? ""}</div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>所属・拠点</div>
                  <div style={infoText}>{showUserData?.baseCity ?? ""}</div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>コメント</div>
                </Stack>
                <div style={commentArea}>{showUserData?.comment ?? ""}</div>
                {showUserData?.snsLink &&
                  showUserData?.snsLink !== "未設定" && (
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ alignItems: "center" }}
                    >
                      <img src={linkIcon} alt="" width={15} height={15} />
                      <div style={grayEllipseLinkStyle}>
                        {isValidUrl(showUserData?.snsLink) ? (
                          <a
                            href={showUserData?.snsLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#37a59a" }}
                          >
                            {showUserData?.snsLink}
                          </a>
                        ) : (
                          "無効なリンク"
                        )}
                      </div>
                    </Stack>
                  )}
                {showUserData?.videoLink &&
                  showUserData?.videoLink !== "未設定" && (
                    <div>
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{ alignItems: "center", mb: 1 }}
                      >
                        <img src={youtubeIcon} alt="" width={15} height={15} />
                        <div style={grayEllipseLinkStyle}>
                          {isValidUrl(showUserData?.snsLink) ? (
                            <a
                              href={showUserData?.videoLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "#37a59a" }}
                            >
                              {showUserData?.videoLink}
                            </a>
                          ) : (
                            "無効なリンク"
                          )}
                        </div>
                      </Stack>
                      <Youtube
                        videoId={showUserData?.videoLink?.substr(
                          showUserData.videoLink.lastIndexOf("/") + 1
                        )}
                      />
                    </div>
                  )}
              </Stack>
            </Box>
          </Box>
        </div>
      </Modal>
    </div>
  );

  function userCard(userData: any, index: number) {
    return (
      <div
        style={{
          width: "95%",
          marginBottom: "1rem",
        }}
        onClick={() => {
          // カードがクリックされたときの処理
          console.log("Card clicked!");
          setShowUserData(userData);
          console.log(index);
          setClickIndex(index);
          prfHandleOpen();
          // 画面遷移を実行する例（遷移先のURLと一緒にデータを渡す）
          // navigate("/userprofile", {
          //   state: {
          //     user: {
          //       displayName: userData.userDisplayName,
          //       photoURL: userData.userPhotoURL,
          //     },
          //     userData: userData,
          //   },
          // });
        }}
      >
        <Stack direction="row" spacing={3} alignItems="center" sx={{ mb: 1 }}>
          <Avatar
            alt="myPhoto"
            src={userData.userPhotoURL!}
            sx={{
              width: 85,
              height: 85,
              border: "3px solid #37a59a", // 縁の設定
            }}
          />
          <Stack direction="column">
            <Stack direction="row" alignItems="end" spacing={1}>
              <Typography
                sx={{
                  ...displayNameStyle,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {userData.userDisplayName}
              </Typography>
              <Typography
                sx={{
                  ...cellLeftStyle,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {userData?.age ?? ""}
              </Typography>
              <Typography
                sx={{
                  ...cellLeftStyle,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {userData?.sex ?? ""}
              </Typography>
              <Typography
                sx={{
                  ...cellLeftStyle,
                  mt: 1,
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {userData?.base ?? ""}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <div style={grayEllipseStyleUserList}>戦型</div>
              <Typography sx={{ ...cellRightStyle }}>
                {userData?.playStyle ?? ""}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <div style={grayEllipseStyleUserList}>フォア</div>
              <Typography sx={{ ...cellRightStyle }}>
                {userData?.fRubber ?? ""}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <div style={grayEllipseStyleUserList}>バック</div>
              <Typography sx={{ ...cellRightStyle }}>
                {userData?.bRubber ?? ""}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
      </div>
    );
  }
}

export default UserList;

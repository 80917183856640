// 情報取得
export const getSessionParseJson = (key: string): any | undefined => {
  const temp = sessionStorage.getItem(key);
  if (temp != null) {
    return JSON.parse(temp);
  }
  return null;
};

// 編集・追加
export const setSessionToJson = (key: string, data: any) => {
  const temp = JSON.stringify(data);
  sessionStorage.setItem(key, temp);
};
